import React, { useState, useEffect } from 'react';
import Loading from '../../Loading';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
 } from "react-reactive-form";
import auth from '../../../services/users/AuthService';

const cctForm = FormBuilder.group({
  state: ["", [Validators.required]],
  region: ["", [Validators.required]],
  sector: ["", [Validators.required]],
  zone: ["", [Validators.required]],
  level: ["", [Validators.required]],
  school: ["", [Validators.required, ]],
});

const SchoolSelectorModal = ({ props, childToParent, school, setSchool }) => {

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(()=>{
    getStates();
    getLevels();
  }, []);

  const [states, setStates] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [zones, setZones] = useState([]);
  const [levels, setLevels] = useState([]);
  const [schools, setSchools] = useState([]);

  const handleReset = () => {
    cctForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('school', school);

    if (school) {
      setSchool(school);
      childToParent(school);
    }
  }

  cctForm.get('state').valueChanges.subscribe((value) => {
    if (cctForm.value.state !== value) {
      setSectors([]);
      cctForm.controls.sector.patchValue("");
      setRegions([]);
      cctForm.controls.region.patchValue("");
      setZones([]); 
      cctForm.controls.zone.patchValue("");
      setSchools([]); 
      cctForm.controls.school.patchValue("");
      getRegions(value);
    }
  });
  cctForm.get('region').valueChanges.subscribe((value) => {
    if (cctForm.value.region !== value) {
      setSectors([]);
      cctForm.controls.sector.patchValue("");
      setZones([]); 
      cctForm.controls.zone.patchValue("");
      getSectors(value);
    }
  });
  cctForm.get('sector').valueChanges.subscribe((value) => {
    if (cctForm.value.sector !== value) {
      setZones([]); 
      cctForm.controls.zone.patchValue("");
      getZones(value);
    }
  });
  cctForm.get('zone').valueChanges.subscribe((value) => {
    if (cctForm.value.zone !== value) {
      setSchools([]);
      cctForm.controls.school.patchValue("");
      getSchools();
    }
  });
  
  cctForm.get('level').valueChanges.subscribe((value) => {
    if (cctForm.value.level !== value) {
      setSchools([]);
      cctForm.controls.school.patchValue("");
      getSchools();
    }
  });
  cctForm.get('school').valueChanges.subscribe((value) => {
    setSchool(value);
  });

  const getStates = () => {
    setIsLoading(true);
    auth.getStates().then(result => {
      setStates(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  const getRegions = (idState) => {
    if(!idState) {
      return ;
    }
    setIsLoading(true);
    auth.getRegions(idState).then(result => {
      setRegions(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  const getSectors = (idRegion) => {
    if(!idRegion) {
      return ;
    }
    setIsLoading(true);
    auth.getSectors(idRegion).then(result => {
      setSectors(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  const getZones = (idSector) => {
    if(!idSector) {
      return ;
    }

    setIsLoading(true);
    auth.getZones(idSector).then(result => {
      setZones(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  const getLevels = () => {
    setIsLoading(true);
    auth.getLevels().then(result => {
      setLevels(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  const getSchools = () => {
    const idZone = cctForm.controls.zone.value;
    const idLevel = cctForm.controls.level.value;

    if(!(idZone && idLevel)) {
      return ;
    }
    setIsLoading(true);
    auth.getSchools(idZone, idLevel).then(result => {
      setSchools(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });
  }

  return (
    <div className="modal fade" id="modalSchoolSelector" tabIndex={-1} role="dialog" aria-labelledby="modalSchoolSelector" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
            {/* Close */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            {/* Heading */}
            <h2 className="fw-bold text-center mb-1" id="modalSchoolSelector">
              ¿De qué eres responsable?
            </h2>
            {/* Text */}
            <p className="font-size-lg text-center text-muted mb-6 mb-md-8">
              Selecciona hasta el nivel a tu cargo.
            </p>
            {/* Form */}
            <FieldGroup
              control={cctForm}
              strict={false}
              render={({ get, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="col-12 mb-3">
                      <FieldControl
                        name="state"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} className="form-control">
                              <option value="" disabled>Estado</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Estado obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Estado" }}
                      />
                    </div>

                    <div className="col-12 mb-3">
                      <FieldControl
                        name="region"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} name="region" className="form-control">
                              <option value="" disabled>Región</option>
                              {regions.map((region,i) => <option key={i} value={region.id}>{region.name}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Región obligatoria'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Región" }}
                      />
                    </div>

                    <div className="col-12 mb-3">
                      <FieldControl
                        name="sector"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} name="sector" className="form-control">
                              <option value="" disabled>Sector</option>
                              {sectors.map((sector,i) => <option key={i} value={sector.id}>{sector.name}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Sector obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Sector" }}
                      />
                    </div>

                    <div className="col-12 mb-3">
                      <FieldControl
                        name="zone"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} name="zones" className="form-control">
                              <option value="" disabled>Zona</option>
                              {zones.map((zone,i) => <option key={i} value={zone.id}>{zone.name} - {zone.cct}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Zona obligatoria'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Zona" }}
                      />
                    </div>

                    <div className="col-12 mb-3">
                      <FieldControl
                        name="level"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} name="levels" className="form-control">
                              <option value="" disabled>Nivel</option>
                              {levels.map((level,i) => <option key={i} value={level.id}>{level.name}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Nivel obligatoria'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Nivel" }}
                      />
                    </div>

                    <div className="col-12 mb-3">
                      <FieldControl
                        name="school"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} name="school" className="form-control">
                              <option value="" disabled>Escuela</option>
                              {schools.map((school,i) => <option key={i} value={school.id}>{school.name} - {school.cct}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Escuela obligatoria'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Escuela" }}
                      />
                    </div>

                  </div>

                 

                  <div className="row mb-3">
                    <div className="col-12">
                      {/* Submit */}
                      <button type="submit" disabled={invalid} className="btn btn-block btn-primary mt-3 lift">
                        Seleccionar Grupos
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>)
}

export default SchoolSelectorModal;
