import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import { Link, withRouter } from 'react-router-dom';
import * as Roles from '../../auth/Roles';
import auth from '../../services/users/AuthService';

const google = window.google;

const Maps = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    // const [municipios, setMunicipios] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState(null);

    React.useEffect(() => {
        if (userState.profile && 
            userState.profile.role && 
            userState.profile.state && 
            userState.profile.role.id === Roles.ROLE_ESTATAL) {
                onStateIdChanged(userState.profile.state.state_id);

        } else {
            getStates();
        }       
    },[userState.schoolYear]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);
            // initMap();
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const getMunicipiosByStateId = (stateId) => {
        setIsLoading(true);
        auth.getMunicipiosByStateId(stateId).then(result => {
            console.log('municipios', result);
            // setMunicipios(result);
            initMap(stateId, result);
            // setStates(result);
            // initMap();
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        getMunicipiosByStateId(stateId);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const initMap = async(stateId, municipios) => {
        const _state = states.find(s => s.id == stateId);

        // Request needed libraries.
        const { Map } = await google.maps.importLibrary("maps");
        const map = new Map(document.getElementById("map"), {
            center: { lat: Number(_state.lat), lng: Number(_state.long) },
            zoom: 8,
            // In the cloud console, configure this Map ID with a style that enables the
            // "Administrative Area Level 1" feature layer.
            mapId: "9c0fd0035790927",
        });

        const datasetLayer = map.getDatasetFeatureLayer("8234bdd0-e93e-4047-857b-8946b489c38b");

        console.log('datasetLayer', datasetLayer);

        datasetLayer.style = (featureStyleFunctionOptions) => {
            const placeFeature = featureStyleFunctionOptions.feature;

            const population = munis[placeFeature.datasetAttributes.NAME_2.toUpperCase()];

            let fillColor;

            // Specify colors using any of the following:
            // * Named ('green')
            // * Hexadecimal ('#FF0000')
            // * RGB ('rgb(0, 0, 255)')
            // * HSL ('hsl(60, 100%, 50%)')

            if (population == null) {
                fillColor = "white";
            } else if (population < 2) {
              fillColor = "#95B853";
            } else if (population < 5) {
              fillColor = "#ECB533";
            } else if (population < 10) {
              fillColor = "#EA7E36";
            } else if (population < 15) {
              fillColor = "#CB2B39";
            } else if (population < 20) {
              fillColor = "#AF1659";
            } else {
              fillColor = "#AA1859";
            }

            return {
              fillColor,
              fillOpacity: 0.5,
            };
        };

      var munis = Object.assign({}, ...(municipios.map(item => ({ [item.name.toUpperCase()]: Number(item.cont) }) )));

      /*
      // Population data by state.
      const municipios = {
        "MÉRIDA": 5039877,
        "CHUMAYEL": 7276316,
        "TIZIMIN": 3025891,
        "CONKAL": 5812069,
        "UMÁN": 1003384,
        "MAXCANÚ": 578803,
        "HUHÍ": 578803,
        "TICUL": 3605597,
      };
      */
    }


    return (<>
            {/* <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" /> */}

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }

            <div className='container'>
                <div className="row mt-5">
                    <div className="col-12 ml-auto">
                        <div id="map"></div>
                    </div>
                </div>
            </div>

            
    </>);

}


export default Maps;