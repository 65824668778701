import React from 'react'
import {
  Redirect,
} from 'react-router-dom'
import auth from '../../services/users/AuthService'
import SignUp from './SignUp'


function SignUpWithoutAuth(props) {
const isAuthenticated = auth.isAuthenticated();
  return (
       isAuthenticated
        ? (
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
        ) : (
          <SignUp />   
        )  
  );
}

export default SignUpWithoutAuth
