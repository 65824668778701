import React from 'react';
import TableOutline from '../../../../components/Charts/TableOutline';
import Bar from '../../../../components/Charts/Bar';
import HorizontalBar3 from '../../../../components/Charts/HorizontalBar3';
import ImgLevels from'../../../../assets/unesco/img/niveles.jpeg';

const ResultadosIDAISection = ({schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        {!(schoolId && currentSchoolYear)? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>3. Resultados del IDAI a nivel escuela</b>
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row justify-content-center text-center mt-5">
                        <div className="col-8">
                            <HorizontalBar3
                                title=""
                                porcent={true}
                                horizontal={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'studentsResponseCiclo4'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {schoolYearId: currentSchoolYear.id}
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'intermedioA', label: 'A', color: '#ff0000', header: 'Intermedio', minWidth: '10px', priority: 3, aggregation: 'sum', custom: 'IF(idaiIntermedio="A", 1, 0)'},
                                        {name: 'intermedioB', label: 'B', color: '#ffff00', header: 'Intermedio', minWidth: '10px', priority: 4, aggregation: 'sum', custom: 'IF(idaiIntermedio="B", 1, 0)'},
                                        {name: 'intermedioC', label: 'C', color: '#bee1b0', header: 'Intermedio', minWidth: '10px', priority: 5, aggregation: 'sum', custom: 'IF(idaiIntermedio="C", 1, 0)'},
                                        {name: 'intermedioD', label: 'D', color: '#5daf33', header: 'Intermedio', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(idaiIntermedio="D", 1, 0)'},
                                        {name: 'intermedioNo', label: 'No Aplica', color: '#b1aaab', header: 'Intermedio', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(idaiIntermedio="null", 1, 0)'},
                                    ]
                                }}
                            />
                        </div>
                        <div className="col-4 mt-5 pt-5">
                            <p style={{color: 'black', textAlign: 'left'}}>Considerando la Meta compartida para cada grado ¿Qué tan cerca se encuentra su escuela de alcanzar esta meta?</p>
                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default ResultadosIDAISection;
