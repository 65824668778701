import React from 'react';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import {columns, saveInfo} from '../../components/CsvImporter/configs/trainers.columns.js';

const TrainersImporter = (props) => {

  return (<>
    <CardBasic title="Importar Formadores">
      <CsvImporter columns={columns} saveInfo={saveInfo} />
    </CardBasic>
  </>);

}

export default TrainersImporter;